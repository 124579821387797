import type { ActionTree, GetterTree, MutationTree } from "vuex";
import { forEach } from "lodash-es";
import type { Form, Microdata } from "../models";
import type { RootState } from "./index";
import type { AxiosInstance } from "axios";

export interface FormState {
  forms: Form[];
  microdata: Microdata[];
}

const state = (): FormState => {
  return {
    forms: [],
    microdata: [],
  };
};

type FormReturnTypeState = ReturnType<typeof state>;

const getters: GetterTree<FormReturnTypeState, RootState> = {
  getMicrodata: (state) => state.microdata,
};

const mutations: MutationTree<FormReturnTypeState> = {
  FETCH_FORMS(state, forms) {
    state.forms = forms;

    state.forms.sort(
      (a, b) =>
        (new Date(b.meta.updatedAt) as any) -
        (new Date(a.meta.updatedAt) as any)
    );
  },
  // Instead of using the store, we could fetch @Event IDs for each carousel
  // and call the jsonld method in Carousel, but then, it would duplicate <script> tags with the same items.
  // We chose to forge each result into one single jsonld object, with doubles.
  FETCH_MICRODATA(state, newData) {
    let microdata = JSON.parse(JSON.stringify(state.microdata)); // deep copy in case of array in array
    let existingEventIds: any[] = [];

    forEach(microdata, (eventId: Record<string, { id: string }>) => {
      const id = eventId["@graph"].id;
      existingEventIds = [...existingEventIds, id];
    });

    forEach(newData, (eventId: Record<string, { id: string }>) => {
      const id = eventId["@graph"].id;
      const toAdd = !existingEventIds.includes(id);

      if (toAdd) {
        microdata = [...microdata, eventId];
      }
    });

    state = { ...state, microdata: microdata.flat() };
  },
};

const getActions = ({
  httpApi,
}: {
  httpApi: AxiosInstance;
}): ActionTree<FormState, RootState> => ({
  async fetchForms ({ commit }, organizationSlug: string) {
    return await httpApi
      .get(`/organizations/${organizationSlug}/directory-forms?pageSize=100`)
      .then(({ data }) => {
        commit("FETCH_FORMS", data.data);
        return data;
      });
  },
  fetchMicrodata({ commit }, data) {
    commit("FETCH_MICRODATA", data);
  },
  async postReport(_context, { reason, userEmail, userMessage, url }) {
    const abuseData = {
      reason,
      userEmail,
      userMessage,
      url,
    };
    return await httpApi.post("/abuses", abuseData);
  },
});

export default ({ httpApi }: { httpApi: AxiosInstance }) => ({
  state,
  getters,
  mutations,
  actions: getActions({ httpApi }),
  namespaced: true,
});
