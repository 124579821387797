import { useRuntimeConfig } from "#imports";

export interface ConfigEnv {
  NUXT_ENV_ALGOLIA_APP_ID: unknown;
  NUXT_ENV_ALGOLIA_API_KEY: unknown;
  NUXT_ENV_AUTH_FRONT: unknown;
  NUXT_ENV_AUTH_URL: unknown;
  NUXT_ENV_BASE_API: unknown;
  NUXT_ENV_BASE_URL: unknown;
  NUXT_ENV_ALGOLIA_BASE_URL: unknown;
  NUXT_ENV_BO_URL: unknown;
  NUXT_ENV_OLD_BO_URL: unknown;
  RECAPTCHA_API_KEY: unknown;
  SEGMENT_ENABLED: unknown;
  SEGMENT_KEY: unknown;
  DD_RUM_CLIENT_TOKEN: unknown;
  DD_ENV: unknown;
  DD_SITE: unknown;
  DD_SERVICE: unknown;
  DD_TRACE_LOG_LEVEL: unknown;
  DD_PROFILING_ENABLED: unknown;
  DD_RUM_SESSION_SAMPLE_RATE: unknown;
  NODE_ENV: unknown;
  NUXT_ENV_ENVIRONMENT: unknown;
  NUXT_DEBUG: unknown;
  PACKAGE_VERSION: unknown;
}

export default (): ConfigEnv => {
  const config = useRuntimeConfig();

  const {
    NUXT_ENV_ALGOLIA_APP_ID,
    NUXT_ENV_ALGOLIA_API_KEY,
    NUXT_ENV_ALGOLIA_BASE_URL,
    NUXT_ENV_AUTH_FRONT,
    NUXT_ENV_AUTH_URL,
    NUXT_ENV_BASE_API,
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BO_URL,
    NUXT_ENV_ENVIRONMENT,
    NUXT_DEBUG,
    NUXT_ENV_OLD_BO_URL,
    RECAPTCHA_API_KEY,
    SEGMENT_ENABLED,
    SEGMENT_KEY,
    DD_RUM_CLIENT_TOKEN,
    DD_ENV,
    DD_SITE,
    DD_SERVICE,
    DD_TRACE_LOG_LEVEL,
    DD_PROFILING_ENABLED,
    DD_RUM_SESSION_SAMPLE_RATE,
    NODE_ENV,
    PACKAGE_VERSION
  } = config.public;

  return {
    NUXT_ENV_ALGOLIA_APP_ID,
    NUXT_ENV_ALGOLIA_API_KEY,
    NUXT_ENV_ALGOLIA_BASE_URL,
    NUXT_ENV_AUTH_FRONT,
    NUXT_ENV_AUTH_URL,
    NUXT_ENV_BASE_API,
    NUXT_ENV_BASE_URL,
    NUXT_ENV_BO_URL,
    NUXT_ENV_ENVIRONMENT,
    NUXT_DEBUG,
    NUXT_ENV_OLD_BO_URL,
    RECAPTCHA_API_KEY,
    SEGMENT_ENABLED,
    SEGMENT_KEY,
    DD_RUM_CLIENT_TOKEN,
    DD_ENV,
    DD_SITE,
    DD_SERVICE,
    DD_TRACE_LOG_LEVEL,
    DD_PROFILING_ENABLED,
    DD_RUM_SESSION_SAMPLE_RATE,
    NODE_ENV,
    PACKAGE_VERSION
  };
};
