import { get, has } from "lodash-es";
import pkg from "atob";
import { ConstantValues } from "../constants";
const { atob: serverAtob } = pkg;

// If an error occurs in the auth API, the following payload is returned
// {
//   "error": "unauthorized_client",
//   "error_description": "client_Id is not set" (optional)
// }
export const ensureResponse = (apiResponse) => {
  if (!apiResponse) {
    throw new Error("Auth api is not responding");
  } else if (has(apiResponse, "error")) {
    const errorTitle = get(apiResponse, "error");
    const errorDetail = get(apiResponse, "error_description");
    const message = errorTitle + (errorDetail ? `: ${errorDetail}` : "");
    throw new Error(message);
  }
};

export const isUserConnected = ($cookies) => {
  const accessToken = $cookies.get(ConstantValues.accessTokenCookieName);
  if (!accessToken) {
    return false;
  }
  const { hasUserId, isUnverified } = getJwtInfo(accessToken);
  return hasUserId && !isUnverified;
};

export const getJwtInfo = (jwt) => {
  const encodedPayload = jwt.split(".")[1];

  // Atob is a native browser function, but you need to import it for the server
  const base64Decoder = import.meta.client ? atob : serverAtob;

  const decodedPayload = JSON.parse(base64Decoder(encodedPayload));
  const tokenExpiration = get(decodedPayload, "exp");

  const utcTimestamp = Date.now().valueOf() / 1000;

  const urs = get(decodedPayload, "urs", []);
  const isAdmin =
    urs.includes(ConstantValues.orgAdmin) ||
    urs.includes(ConstantValues.formAdmin);

  return {
    // if exp claim is not present token is always valid
    isExpired: !tokenExpiration || utcTimestamp > tokenExpiration,
    isUnverified: get(decodedPayload, "uvf") === "1",
    hasUserId: has(decodedPayload, "uid"),
    isAdmin,
  };
};
