export const appBaseURL = "/"

export const appBuildAssetsDir = "/_ex/"

export const appHead = {"meta":[{"charset":"utf-8"},{"http-equiv":"X-UA-Compatible","content":"chrome=1, ie=edge"},{"name":"author","content":"HelloAsso"},{"property":"og:site_name","content":"HelloAsso"},{"property":"og:type","content":"website"},{"property":"og:locale","content":"fr_FR"},{"hid":"og:image","property":"og:image","content":"$NUXT_ENV_BASE_URL/_ex/social-default.jpg"},{"hid":"og:image:width","property":"og:image:width","content":1200},{"hid":"og:image:height","property":"og:image:height","content":630},{"name":"twitter:site","content":"@helloasso"},{"name":"twitter:card","content":"summary_large_image"},{"hid":"twitter:image","property":"twitter:image","content":"$NUXT_ENV_BASE_URL/_ex/social-default.jpg"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"description","hid":"description","content":"La solution de paiement alternative des associations entièrement gratuite. Gérez vos activités en toute simplicité !"}],"link":[{"rel":"icon","href":"/_ex/icons/favicon.ico","type":"image/x-icon"},{"rel":"icon","sizes":"16x16","href":"/_ex/icons/favicon-16x16.png"},{"rel":"icon","sizes":"32x32","href":"/_ex/icons/favicon-32x32.png"},{"rel":"icon","sizes":"96x96","href":"/_ex/icons/favicon-96x96.png"},{"rel":"icon","sizes":"192x192","href":"/_ex/icons/android-icon-192x192.png"},{"rel":"apple-touch-icon","sizes":"120x120","href":"/_ex/icons/apple-touch-icon-120x120.png"},{"rel":"apple-touch-icon","sizes":"152x152","href":"/_ex/icons/apple-touch-icon-152x152.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/_ex/icons/apple-touch-icon-180x180.png"}],"style":[],"script":[],"noscript":[],"htmlAttrs":{"lang":"fr","hads":true},"title":"Explore | HelloAsso"}

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000