import { defineNuxtPlugin } from '#imports'
import { datadogRum } from '@datadog/browser-rum'
import useDebug from '~/domains/app/composables/debug.composable';


export default defineNuxtPlugin((nuxtApp) => {
  const debug = useDebug()

  nuxtApp.hook('vue:error', (error, instance, info) => {
    debug.warn('[nuxt-plugin] [error-handler] hook:vue:error', { error, instance, info });
    datadogRum.addError(error, {
      origin: 'hook:vue:error',
      instance,
      info
    })
  })

  nuxtApp.hook('app:error', (error) => {
    debug.warn('[nuxt-plugin] [error-handler] hook:app:error', { error });
    datadogRum.addError(error, {
      origin: 'hook:app:error'
    })
  })
})