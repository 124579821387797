import { datadogRum, DefaultPrivacyLevel, type RumInitConfiguration } from '@datadog/browser-rum'
import { defineNuxtPlugin, useRuntimeConfig } from '#imports'
import useDebug from '~/domains/app/composables/debug.composable';

export default defineNuxtPlugin(() => {
  const debug = useDebug()
  try {    
    debug.info('[nuxt.plugin][datadog-rum]');

    // Get runtime config
    const config = useRuntimeConfig()

    // Check config
    if (!config.public.DD_RUM_APP_ID || !config.public.DD_RUM_CLIENT_TOKEN) {
      debug.warn('[nuxt.plugin][datadog-rum] Plugin config is not valid');
      return
    }

    // Define config
    const rumInitConfig: RumInitConfiguration = {
      applicationId: config.public.DD_RUM_APP_ID as string,
      clientToken: config.public.DD_RUM_CLIENT_TOKEN as string,
      version: config.public.PACKAGE_VERSION as string,
      site: config.public.DD_SITE as string,
      service: config.public.DD_SERVICE as string,
      env: config.public.DD_ENV as string,
      sessionSampleRate: parseInt(config.public.DD_RUM_SESSION_SAMPLE_RATE as string),
      sessionReplaySampleRate: parseInt(config.public.DD_RUM_SESSION_REPLAY_SAMPLE_RATE as string),
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
      startSessionReplayRecordingManually: true,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
    }
    debug.info('[plugin][datadog-rum] Config', rumInitConfig);

    // Init Datadog RUM
    datadogRum.init(rumInitConfig)
    debug.info('[plugin][datadog-rum] Plugin was initialized');

    // Start session recording
    datadogRum.startSessionReplayRecording()
    debug.info('[plugin][datadog-rum] Start session replay recording');

  } catch (error) {
    // Report error
    debug.error('[plugin][datadog-rum] Error: ', error);
  }
})
