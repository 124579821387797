// import https from 'https'
import axios from "axios";
import { getJwtInfo } from "./auth.service";
import type { ConfigEnv } from "@/composables/useConfigEnv";
// import { accessTokenCookieName, refreshTokenCookieName } from '../constants'
const accessTokenCookieName = "tm5-HelloAsso";
const refreshTokenCookieName = "rm5-HelloAsso";

const interceptorResponse = (response) => response;
const interceptorError = async (error) => await Promise.reject(error);

/**
 * Get url of nuxt's serveur
 * @returns {String}
 */
/* We have to prefix all server middleware url by '/forms/' otherwise ha-proxy make bad redirection */
const getProxyBaseURL = (configEnv: ConfigEnv): string => `${configEnv.NUXT_ENV_BASE_URL}/forms`;

/**
 * Create https agent
 * @returns {Agent}
 */
// const getHttpsAgent = (configEnv: ConfigEnv) => {
//   return new https.Agent({
//     rejectUnauthorized: configEnv.NODE_ENV !== 'development'
//   })
// }

/**
 * Create axios instance for oauth2
 * We use it only in nuxt's server to obfuscate api and client's id
 * @returns {AxiosInstance}
 */
const createHttpAuth = (configEnv: ConfigEnv) => {
  const httpAuth = axios.create({
    baseURL: configEnv.NUXT_ENV_AUTH_URL as string,
    withCredentials: true,
  });
  return httpAuth;
};

/**
 * Create axios instance for api v5
 * @returns {AxiosInstance}
 */
const createHttpApi = (configEnv: ConfigEnv) => {
  const httpApi = axios.create({
    withCredentials: true,
    // httpsAgent: getHttpsAgent(configEnv),
    baseURL: configEnv.NUXT_ENV_BASE_API as string,
  });
  return httpApi;
};

/**
 * Create axios instance for auth proxy
 * We use it to make oauth2 calls in nuxt's server and obfuscate api and client's id
 * @returns {AxiosInstance}
 */
const createHttpProxyAuth = (configEnv: ConfigEnv) => {
  const httpProxyAuth = axios.create({
    withCredentials: true,
    // httpsAgent: getHttpsAgent(configEnv),
    baseURL: `${getProxyBaseURL(configEnv)}/auth`,
  });
  httpProxyAuth.interceptors.response.use(
    interceptorResponse,
    interceptorError
  );
  return httpProxyAuth;
};

/**
 * Create axios instance for api proxy
 * We use it to retry api calls in nuxt's server in case of network error
 * @returns {AxiosInstance}
 */
const createHttpProxyApi = (configEnv: ConfigEnv) => {
  const httpProxy = axios.create({
    withCredentials: false,
    // httpsAgent: getHttpsAgent(configEnv),
    baseURL: getProxyBaseURL(configEnv),
  });
  httpProxy.interceptors.response.use(interceptorResponse, interceptorError);
  return httpProxy;
};

/**
 * Makes the request to get token data
 * Made to easily keep a token instance or start a new one if needed
 * @param {[{refreshToken: string}]} payload - leave empty to request a brand new token
 * @returns {{access_token: string, request_token: string, exp: number}}
 */
const fetchToken = async (
  configEnv: ConfigEnv,
  payload?: { refreshToken: string }
) => {
  const httpProxyAuth = createHttpProxyAuth(configEnv);
  const response = await httpProxyAuth.post("/token", payload);
  return response.data;
};

/**
 * Catch 401 errors
 * Get a brand new access_token
 * Retry the failed request with the new access_token
 * This interceptor is a fallback if a user tries to access a page with an expired access_token + an expired refresh_token
 * @param {AxiosInstance} httpApi
 */
const httpForceAuthentication = async (configEnv: ConfigEnv, error) => {
  const { access_token } = await fetchToken(configEnv);
  const { url, method, data, headers: previousResponseHeaders } = error.config;
  const Authorization = `Bearer ${access_token}`;

  return await createHttpApi(configEnv)({
    url,
    method,
    data,
    headers: { ...previousResponseHeaders, Authorization },
  });
};
/**
 * Add response interceptor to httpApi to retry request in network error in nuxt's server with httpProxyApi
 * @param {AxiosInstance} httpApi
 * @returns {Object} config
 */
const httpApiRetry = async (configEnv: ConfigEnv, error) => {
  const httpProxyApi = createHttpProxyApi(configEnv);
  /*
      In case of safari network error we have to use server middleware to send request from server side
      Ticket: https://dev.azure.com/helloasso/HelloAsso/_workitems/edit/16556
  */
  const request = error?.config;
  const { url, method, data, headers } = request;
  return await httpProxyApi({
    method: "post",
    data: { url, method, data, headers },
    url: "/api",
  });
};

/**
 * Get Bearer Authorization from existing access cookie
 * @param {$cookies} $cookies
 * @returns {String}
 */
const getBearerToken = ($cookies) => {
  const cookieToken = $cookies.get(accessTokenCookieName);
  const cookieTokenIsValid = cookieToken
    ? !getJwtInfo(cookieToken).isExpired
    : false;
  return cookieTokenIsValid ? `Bearer ${cookieToken}` : null;
};

/**
 * Get Bearer Authorization from existing and new access cookie
 * @param {$cookies} $cookies
 * @returns {Promise<String>}
 */
const getAuthorizationBearer = async (configEnv: ConfigEnv, $cookies) => {
  const bearerToken = getBearerToken($cookies);
  if (bearerToken) {
    return bearerToken;
  }

  const refreshToken = $cookies.get(refreshTokenCookieName);
  const { access_token } = await fetchToken(
    configEnv,
    refreshToken ? { refreshToken } : undefined
  );

  return `Bearer ${access_token}`;
};

/**
 * Add request interceptor to httpProxyAuth to add Bearer Authorization to headers
 * @param {AxiosInstance} httpProxyAuth
 * @param {$cookies} $cookies
 * @returns {Object} config
 */
const addHttpProxyAuthAuthorization = (httpProxyAuth, $cookies) => {
  httpProxyAuth.interceptors.request.use((config) => {
    const { headers } = config;
    const bearerToken = getBearerToken($cookies);
    if (bearerToken) {
      headers.Authorization = bearerToken;
    }
    return { ...config, headers };
  });
};

/**
 * Add request interceptor to httpApi to add Bearer Authorization to headers
 * @param {AxiosInstance} httpApi
 * @param {$cookies} $cookies
 * @returns {Object} config
 */
const addHttpApiAuthorization = (httpApi, configEnv: ConfigEnv, $cookies) => {
  httpApi.interceptors.request.use(async (config) => {
    const { headers } = config;
    const authorizationBearer = await getAuthorizationBearer(
      configEnv,
      $cookies
    );
    if (authorizationBearer) {
      headers.Authorization = authorizationBearer;
    }
    return {
      ...config,
      headers,
    };
  });
};

const addErrorInterceptor = (httpApi, configEnv: ConfigEnv) => {
  httpApi.interceptors.response.use(interceptorResponse, async (error) => {
    if (error?.response?.status === 401) {
      return await httpForceAuthentication(configEnv, error);
    }
    if (error?.message === "Network Error") {
      return await httpApiRetry(configEnv, error);
    }
    return await interceptorError(error);
  });
};

export {
  createHttpAuth,
  createHttpProxyApi,
  createHttpApi,
  createHttpProxyAuth,
  addHttpApiAuthorization,
  addHttpProxyAuthAuthorization,
  addErrorInterceptor,
};
