import type { ActionTree, GetterTree, MutationTree } from "vuex"
import type { RootState } from "./index"
import type { Organization, Profile } from "@/models"
import { getJwtInfo, ensureResponse } from "@/services/auth.service"
import type { AxiosInstance } from "axios"

export const SET_USER = "SET_USER"
export const SET_USER_ORGANIZATIONS = "SET_USER_ORGANIZATIONS"
export const SELECT_USER_ORGANIZATION = "SELECT_USER_ORGANIZATION"
export const REVOKE_USER = "REVOKE_USER"
export const SET_IS_CONNECTED = "SET_IS_CONNECTED"

interface UsersState {
  profile: Profile
  organizations: Organization[]
  selectedOrg: Organization
  isLoading: boolean
}

const state = (): UsersState => ({
  profile: {},
  organizations: [
    // ...user organizations
  ],
  selectedOrg: {
    // ...selected organization
  },
  isLoading: false,
})

type UserState = ReturnType<typeof state>

const getters: GetterTree<UserState, RootState> = {
  getMe: (state) => () => state.profile,
  getUserOrganizations: (state) => () => state.organizations,
  getUserSelectedOrg: (state) => () => state.selectedOrg,
  isLoading: (state) => () => state.isLoading,
  isAdmin: (state) => (organization: any) => {
    return !(
      state.organizations?.find(
        ({ organizationSlug }) => organizationSlug === organization
      ) == null
    )
  },
}

const mutations: MutationTree<UserState> = {
  [SET_USER](state, user) {
    state.profile = user
  },
  [SELECT_USER_ORGANIZATION](state, organization = {}) {
    state.selectedOrg = organization
  },
  [REVOKE_USER](state) {
    state.profile = {}
    state.selectedOrg = {}
    state.organizations = []
  },
  [SET_USER_ORGANIZATIONS](state, organizations) {
    state.organizations = organizations
  },
}

const getActions = ({
  httpApi,
  httpAuth,
}: {
  httpApi: AxiosInstance
  httpAuth: AxiosInstance
}): ActionTree<UserState, RootState> => ({
  async fetchUser({ commit }) {
    const { data } = await httpApi.get("/agg/user")
    const { user, organizations } = data
    commit(SET_USER, user)
    // default select the first org in the list if the user did not have any orgs stored locally
    const hasOrgs = getters.getUserOrganizations.length > 0
    if (organizations?.length > 0 && !hasOrgs) {
      commit(SELECT_USER_ORGANIZATION, organizations[0])
    }
    commit(SET_USER_ORGANIZATIONS, organizations)
    return { user, organizations }
  },

  selectUserOrganization({ commit }, organization) {
    commit(SELECT_USER_ORGANIZATION, organization)
  },

  async disconnect({ commit }) {
    await httpAuth
      .get("/disconnect")
      .catch(console.error)
      .finally(() => {
        commit(REVOKE_USER)
      })
  },

  async connect(
    { dispatch },
    [username, password, organizationSlug, accessToken]: [
      string,
      string,
      string,
      string
    ]
  ) {
    try {
      const connectResponse = await httpAuth.post(
        "/token",
        { username, password },
        {
          // add token header to 'upgrade' the token and preserve user session
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      )

      ensureResponse(connectResponse)

      const { isAdmin } = getJwtInfo(connectResponse?.data?.access_token)

      // if user has FormAdmin rights, it doesn't mean he's admin of the current form
      // reload the form with the new access token to get this information (hasAdminRights)
      const promises = [dispatch("fetchUser")]

      if (isAdmin && organizationSlug) {
        promises.push(
          dispatch("forms/fetchForms", organizationSlug, { root: true })
        )
      }

      return await Promise.all(promises)
    } catch (error) {
      console.error(error)
      return error
    }
  },
})

export default ({
  httpApi,
  httpAuth,
}: {
  httpApi: AxiosInstance
  httpAuth: AxiosInstance
}) => ({
  state,
  getters,
  mutations,
  actions: getActions({ httpApi, httpAuth }),
  namespaced: true,
})
