import axios, { type AxiosResponse } from "axios";
import type { ActionTree, GetterTree, MutationTree } from "vuex";
import type {
  Address,
  AddressProperties,
  NormalizedCoordinatesObject,
  NormalizedCoordinatesArray,
  Feature,
} from "../models/Location.interface";
import type { RootState } from "./index";

export interface IAddressState {
  addresses: Address[];
  location: Address;
  locations: Address[];
  recentLocations: Address[];
  geoLocation: NormalizedCoordinatesObject;
}

const state = (): IAddressState => ({
  addresses: [],
  location: {
    properties: {},
  },
  locations: [],
  recentLocations: [],
  geoLocation: {
    latitude: undefined,
    longitude: undefined,
  },
});

type AddressState = ReturnType<typeof state>;

const getters: GetterTree<AddressState, RootState> = {
  getLocation: (state) => state.location,
  getRecentLocations: (state) => state.recentLocations,
  getGeoLocation: (state) => state.geoLocation,
};

const mutations: MutationTree<AddressState> = {
  GET_ADDRESSES(state, addresses: Address[]) {
    state.addresses = addresses;
  },
  GET_LOCATIONS(state, locations: Address[]) {
    state.locations = locations;
  },
  SET_LOCATION(state, location: Address) {
    Object.assign(state, { ...state, location });
  },
  SET_RECENT_LOCATIONS(state, { location }: { location: Address }) {
    let recentLocations = state.recentLocations;
    recentLocations = recentLocations.filter((item: Address) => {
      return (
        item.properties.department !== location.properties.department &&
        item.properties.city !== location.properties.city
      );
    });
    if (recentLocations.length === 3) {
      recentLocations.pop();
    }

    recentLocations.unshift(location);
    Object.assign(state, { ...state, recentLocations });
  },
  SET_GEOLOCATION(state, geoLocation: NormalizedCoordinatesObject) {
    state.geoLocation = geoLocation;
  },
};

const getActions = (): ActionTree<AddressState, RootState> => ({
  setLocation({ commit }, location: Address) {
    commit("SET_LOCATION", { location });
    commit(
      "filters/SET_LOCATION",
      { value: location.properties },
      { root: true }
    );
    commit("SET_RECENT_LOCATIONS", { location });
  },
  setRecentLocations({ commit }, location: Address) {
    commit("SET_RECENT_LOCATIONS", { location });
  },
  async getAddresses({ commit }, query: string) {
    const response = await axios.get(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
        query.replace(/ /gm, "+")
      )}&limit=5&autocomplete=1`
    );

    if (response.status === 200) {
      const addresses = response.data.features.map((feature: Feature) => ({
        properties: {
          city: feature.properties.city,
          department: feature.properties.context?.split(",")[1].trim(),
          departementCode: feature.properties.postcode?.substring(0, 2),
          district: feature.properties.codesPostaux,
          geometry: feature.geometry.coordinates,
          id: `${feature.properties.postcode?.substring(0, 2) ?? ""}_${
            feature.properties.name ?? ""
          }`,
          label: feature.properties.label,
          name: feature.properties.name,
          score: feature.properties._score,
          type: feature.properties.type,
          zipcode: feature.properties.postcode,
        },
      })) as Address[];

      commit("GET_ADDRESSES", addresses);
      return addresses;
    }
    console.error(response);
  },
  async getLocations({ commit }, query: string) {
    return await axios
      .get(
        `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
          query.replace(/ /gm, "+")
        )}&limit=5&type=municipality&autocomplete=1`
      )
      .then((response) => {
        const addresses = response.data.features.map((feature: Feature) => ({
          properties: {
            city: feature.properties.city,
            department: feature.properties.context?.split(",")[1].trim(),
            departementCode: feature.properties.postcode?.substring(0, 2),
            district: feature.properties.codesPostaux,
            geometry: feature.geometry.coordinates,
            id: `${feature.properties.postcode?.substring(0, 2) ?? ""}_${
              feature.properties.name ?? ""
            }`,
            label: feature.properties.label,
            name: feature.properties.name,
            score: feature.properties._score,
            type: feature.properties.type,
            zipcode: feature.properties.postcode,
          },
        })) as Address[];
        commit("GET_LOCATIONS", addresses);
        return addresses;
      })
      .catch((error) => {
        console.error(error);
        throw error;
      }); // Data gouv return a network error if (query.length < 3)...
  },
  async reverseGeoLoc(_, coords: GeolocationCoordinates) {
    const baseUrl = "https://api-adresse.data.gouv.fr/reverse";

    const response = await axios.get(
      `${baseUrl}/?lat=${coords.latitude}&lon=${coords.longitude}`
    );

    if (response.status === 200) {
      return response.data?.features[0].properties as AddressProperties | null;
    } else {
      console.error(response);
    }
  },
  async getLatLongWithCity({ commit }, city: string) {
    const searchQuery: string = city.replace(/ /gm, "+");

    const response: AxiosResponse = await axios.get(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
        searchQuery
      )}&limit=1&type=municipality&autocomplete=1`
    );

    const {
      data: { features: addresses },
    } = response;

    // Returned coordinates from datagouv API are inverted
    const [longitude, latitude] = addresses[0].geometry.coordinates;

    // Update map coordinates
    commit("SET_GEOLOCATION", {
      latitude,
      longitude,
    });

    return [longitude, latitude] as NormalizedCoordinatesArray;
  },
  async fetchManualAddress(
    { commit },
    {
      address,
      zipCode,
      city,
    }: { address: string; zipCode: string; city: string }
  ) {
    const queryString = `${address} ${zipCode} ${city}`;
    const searchQuery = queryString.replace(/ /gm, "+");

    const response: AxiosResponse = await axios.get(
      `https://api-adresse.data.gouv.fr/search/?q=${encodeURI(
        searchQuery
      )}&limit=1`
    );

    const {
      data: { features: addresses },
    } = response;
    // Returned coordinates from datagouv API are inverted
    const [longitude, latitude] = addresses[0].geometry.coordinates;

    // Update map coordinates
    commit("SET_GEOLOCATION", {
      latitude,
      longitude,
    });
    return {
      address: addresses[0].properties.name,
      zipCode: addresses[0].properties.postcode,
      city: addresses[0].properties.city,
      coordinates: [latitude, longitude],
    };
  },
});

export default () => ({
  state,
  getters,
  mutations,
  actions: getActions(),
  namespaced: true,
});
