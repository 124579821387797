import { ConstantValues } from "@/constants";
import Cookies from 'universal-cookie';
import { useConfigEnv, useRequestHeaders } from "#imports";
export default () => {
  const headers = useRequestHeaders(['cookie'])
  const cookies = new Cookies(headers.cookie, { path: '/' });
  const domain = (useConfigEnv().NUXT_ENV_BASE_URL as string).replace('https://www', '')
  const getAccessToken = () => {
    if(import.meta.client)
      return cookies.get(ConstantValues.accessTokenCookieName);
  }

  const resetAccessToken = () => {
    if(import.meta.client)
      return cookies.remove(ConstantValues.accessTokenCookieName, { domain });
  }

  const resetRefreshToken = () => {
    if(import.meta.client)
      return cookies.remove(ConstantValues.refreshTokenCookieName, { domain });
  }

  const resetSessionToken = () => {
    if(import.meta.client)
      return cookies.remove(ConstantValues.sessionTokenCookieName, { domain });
  }

  const clearTokens = () => {
    resetAccessToken()
    resetRefreshToken()
    resetSessionToken()
  }

  return {
    cookies,
    getAccessToken,
    resetAccessToken,
    resetRefreshToken,
    resetSessionToken,
    clearTokens
  };
};
