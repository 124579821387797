import { functions } from '@ha/helpers'
import type { Filters } from '~/domains/search/interfaces/filters.interface';

/**
 * convert params to filter.
 * Remove `--` for space and `---` for apostrophe
 * @param paramValue 
 * @returns 
 */
export const convertParamsThematicToFilters = (params: any): Filters => {
  const filters = {}
  for (const mockPageKey in params) {
    if (Object.hasOwn(params, mockPageKey)) {
      const paramValue = params[mockPageKey];
      filters[mockPageKey] = convertParamThematicToFilter(paramValue)
    }
  }
  return filters
}

/**
 * Remove `--` for space and `---` for apostrophe
 * @param paramValue 
 * @returns 
 */
export const convertParamThematicToFilter = (paramValue: string): string => {
  if(typeof paramValue !== 'string') {
    throw new Error(`Filter value is not a string. Receive '${typeof paramValue}'`)
  }
  return functions
    .deburr(paramValue?.toLowerCase())
    .replace(/---/g, '\'')
    .replace(/--/g, ' ')
}

/**
 * Convert filters to params.
 * Replace space by `--`, apostrophe by `---` and ampersand by `et`
 * @param paramValue 
 * @returns 
 */
export const convertFiltersToParamThematics = (filters: any): Filters => {
  const params = {}
  for (const mockPageKey in filters) {
    if (Object.hasOwn(filters, mockPageKey)) {
      const filterValue = filters[mockPageKey];
      params[mockPageKey] = convertFilterToParamThematic(filterValue)
    }
  }
  return params
}

/**
 * Replace space by `--`, apostrophe by `---` and ampersand by `et`
 * @param paramValue 
 * @returns 
 */
export const convertFilterToParamThematic = (filterValue: string): string => {
  if(typeof filterValue !== 'string') {
    throw new Error(`Filter value is not a string. Receive '${typeof filterValue}'`)
  }
  return functions
    .deburr(filterValue?.toLowerCase())
    .replace(/['’]/g, '---')
    .replace(/\s/g, '--')
    .replace(/&/g, 'et')
}
